import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";

import FormProvider from "src/components/hook-form/FormProvider";
import { changePasswordEndpoint } from "src/urls";
import { createPasswordChangeErrorMessage } from "src/pages/welcome/WelcomePage";
import { FIVE_SECONDS } from "src/utils/duration";
import { errorToast, successToast } from "src/components_with_stories/toast";
import useIsLightMode from "src/hooks/useIsLightMode";
import PasswordField from "src/newComponents/form/PasswordField";

interface ChangePasswordModalProps {
  handleModalClose: () => void;
  closeUserProfile: () => void;
}

const setNewPasswordSchema = () => {
  return Yup.object().shape({
    password1: Yup.string().required("Password is required.").min(8, "Your password must be at least 8 characters."),
    password2: Yup.string()
      .required("Confirm password is required.")
      .min(8, "Your password must be at least 8 characters.")
      .oneOf([Yup.ref("password1")], "Your passwords do not match."),
  });
};

const ChangePasswordModal = ({ handleModalClose, closeUserProfile }: ChangePasswordModalProps) => {
  const isLightMode = useIsLightMode();

  const defaultValues = {
    password1: "",
    password2: "",
  };

  const methods = useForm({
    resolver: yupResolver(setNewPasswordSchema()),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async ({ password1, password2 }: { password1: string; password2: string }) => {
    if (password1 === password2) {
      axios
        .post(changePasswordEndpoint, {
          new_password1: password1,
          new_password2: password1,
        })
        .then(() => {
          reset();
          successToast("Password changed successfully.", isLightMode, FIVE_SECONDS);
          handleModalClose();
          closeUserProfile();
        })
        .catch((error) => {
          const data = error.response.data;

          const passwordErrors = data.new_password2;
          if (passwordErrors) {
            const errorMessage = createPasswordChangeErrorMessage(passwordErrors);
            errorToast(errorMessage, isLightMode);
          }
        });
    } else {
      errorToast("Passwords don't match!", isLightMode);
    }
  };

  return (
    <div>
      <Dialog
        onClose={handleModalClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ paddingRight: "2.75rem", paddingBottom: "0px" }}>
          <span>Change Password</span>
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            paddingBottom: 0,
            marginBottom: "1rem",
            marginTop: "1rem",
            paddingLeft: "1rem",
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2.5} alignItems="flex-end">
              <PasswordField name="password1" label="Password" hasFocus={true} tabIndex1={1} tabIndex2={4} />

              <PasswordField name="password2" label="Confirm Password" hasFocus={false} tabIndex1={2} tabIndex2={5} />

              <LoadingButton
                fullWidth
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                tabIndex={3}
              >
                Confirm
              </LoadingButton>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export { ChangePasswordModal, setNewPasswordSchema };
