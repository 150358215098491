import React, { Suspense } from "react";
import { Stack, Typography, useTheme } from "@mui/material";
const Slider = React.lazy(() => import("react-slick"));

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ecg from "src/assets/companies/images/ecg.png";
import npa from "src/assets/companies/images/npa.png";
import bpsa from "src/assets/companies/images/bpsa.png";
import ukbpa from "src/assets/companies/images/ukbpa.png";
import pharmacyShow from "src/assets/companies/images/pharmacy_show.png";
import clinicalPharmacyCongress from "src/assets/companies/images/clinical_pharmacy_congress.png";
import chemistAndDruggist from "src/assets/companies/images/chemist_and_druggist.png";
import kandarpThakkarConsulting from "src/assets/companies/images/kandarp_thakkar_consulting.png";

import kingstonUni from "src/assets/companies/uni_images/kingston_uni.png";
import portsmouthUni from "src/assets/companies/uni_images/portsmouth_uni.png";
import birminghamUni from "src/assets/companies/uni_images/birmingham_uni.png";
import keeleUni from "src/assets/companies/uni_images/keele_uni.png";
import eastAngliaUni from "src/assets/companies/uni_images/university_of_east_anglia.png";
import brightonUni from "src/assets/companies/uni_images/brighton_uni.png";
import uclanUni from "src/assets/companies/uni_images/uclan.png";
import astonUni from "src/assets/companies/uni_images/aston_uni.png";
import demontfortUni from "src/assets/companies/uni_images/de_montfort_uni.png";

import paydensPharmacy from "src/assets/pharmacies/1.svg";
import jardinesPharmacy from "src/assets/pharmacies/2.png";
import universityOfWolverhampton from "src/assets/pharmacies/3.jpg";

import useResponsive from "src/hooks/useResponsive";

const CompaniesSPC = () => {
  const theme = useTheme();

  const companies = [
    <img
      key="company1"
      src={ecg}
      alt="ECG"
      title="ECG"
      style={{ width: "120px", height: "auto", marginLeft: "auto" }}
      loading="lazy"
    />,
    <img
      key="company2"
      src={npa}
      alt="National Pharmacy Association"
      title="National Pharmacy Association"
      style={{
        width: "95px",
        height: "auto",
        marginTop: "5px",
        marginLeft: "50%",
      }}
      loading="lazy"
    />,
    <img
      key="company3"
      src={bpsa}
      alt="British Pharmaceutical Students' Association"
      title="British Pharmaceutical Students' Association"
      style={{
        width: "150px",
        height: "auto",
        marginTop: "-10px",
        marginLeft: "auto",
      }}
      loading="lazy"
    />,
    <img
      key="company4"
      src={ukbpa}
      alt="UK Black Pharmacists Association"
      title="UK Black Pharmacists Association"
      style={{
        width: "150px",
        height: "auto",
        marginLeft: "auto",
      }}
      loading="lazy"
    />,
    <img
      key="company5"
      src={pharmacyShow}
      alt="Pharmacy Show"
      title="Pharmacy Show"
      style={{
        width: "185px",
        height: "auto",
        marginTop: "-5px",
        marginLeft: "auto",
      }}
      loading="lazy"
    />,
    <img
      key="company6"
      src={clinicalPharmacyCongress}
      alt="Clinical Pharmacy Congress"
      title="Clinical Pharmacy Congress"
      style={{
        width: "185px",
        height: "auto",
        marginTop: "10px",
        marginLeft: "35%",
      }}
      loading="lazy"
    />,
    <img
      key="company7"
      src={chemistAndDruggist}
      alt="Chemist and Drugist"
      title="Chemist and Drugist"
      style={{
        width: "85px",
        height: "auto",
        marginTop: "0px",
        marginLeft: "50%",
      }}
      loading="lazy"
    />,
    <img
      key="company8"
      src={kandarpThakkarConsulting}
      alt="Kandarp Thakkar Consulting"
      title="Kandarp Thakkar Consulting"
      style={{
        width: "170px",
        height: "auto",
        marginTop: "7px",
        marginLeft: "auto",
      }}
      loading="lazy"
    />,
  ];

  const universities = [
    <img
      key="uni1"
      src={kingstonUni}
      alt="University of Kingston"
      title="University of Kingston"
      style={{
        width: "90px",
        height: "auto",
        marginTop: "5px",
        marginLeft: "50%",
      }}
      loading="lazy"
    />,
    <img
      key="uni2"
      src={portsmouthUni}
      alt="University of Portsmouth"
      title="University of Portsmouth"
      style={{
        width: "150px",
        height: "auto",
        marginTop: "5px",
        marginLeft: "auto",
      }}
      loading="lazy"
    />,
    <img
      key="uni3"
      src={birminghamUni}
      alt="University of Birmingham"
      title="University of Birmingham"
      style={{
        width: "150px",
        height: "auto",
        marginTop: "10px",
        marginLeft: "auto",
      }}
      loading="lazy"
    />,
    <img
      key="uni4"
      src={keeleUni}
      alt="Keele University"
      title="Keele University"
      style={{
        width: "135px",
        height: "auto",
        marginTop: "3px",
        marginLeft: "auto",
      }}
      loading="lazy"
    />,
    <img
      key="uni5"
      src={eastAngliaUni}
      alt="University of East Anglia"
      title="University of East Anglia"
      style={{
        width: "110px",
        height: "auto",
        marginTop: "0px",
        marginLeft: "35%",
      }}
      loading="lazy"
    />,
    <img
      key="uni6"
      src={brightonUni}
      alt="University of Brighton"
      title="University of Brighton"
      style={{
        width: "180px",
        height: "auto",
        marginTop: "4px",
        marginLeft: "auto",
      }}
      loading="lazy"
    />,
    <img
      key="uni7"
      src={uclanUni}
      alt="University of Central Lancashire"
      title="University of Central Lancashire"
      style={{
        width: "150px",
        height: "auto",
        marginTop: "6px",
        marginLeft: "auto",
      }}
      loading="lazy"
    />,
    <img
      key="uni8"
      src={astonUni}
      alt="University of Aston"
      title="University of Aston"
      style={{ width: "150px", height: "auto", marginLeft: "auto" }}
      loading="lazy"
    />,
    <img
      key="uni9"
      src={demontfortUni}
      alt="De Montfort University Leicester"
      title="De Montfort University Leicester"
      style={{ width: "145px", height: "auto", marginLeft: "auto" }}
      loading="lazy"
    />,
  ];

  const allBrands = companies.concat(universities);

  const responsive = [
    {
      breakpoint: theme.breakpoints.values.lg,
      settings: { slidesToShow: 4 },
    },
    {
      breakpoint: theme.breakpoints.values.md,
      settings: { slidesToShow: 3 },
    },
    {
      breakpoint: theme.breakpoints.values.sm,
      settings: { slidesToShow: 2 },
    },
  ];

  const carouselSettings = {
    arrows: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: true,
    speed: 8000,
    cssEase: "linear",
    autoplaySpeed: 0,
    responsive: responsive,
    draggable: false,
    pauseOnHover: false,
    touchMove: false,
  };

  return (
    <>
      <Stack
        spacing={3}
        sx={{
          textAlign: "center",
          mb: { xs: 6, md: 8 },
          ml: { xs: 2.5, sm: 0 },
          mr: { xs: 2.5, sm: 0 },
        }}
      >
        <Typography variant="h2">We Work With</Typography>
        <Typography sx={{ color: "text.secondary" }}>
          Pharmacies we partner with on our Foundation Training Programme
        </Typography>
        <Pharmacies />
        <Typography sx={{ color: "text.secondary" }}>
          Check our past and current collaborations with leading organisations and experts in education.
        </Typography>
      </Stack>

      <Suspense fallback={<div>Loading...</div>}>
        <Slider {...carouselSettings}>
          {allBrands.map((ImageToRender, idx) => {
            return <div key={idx}>{ImageToRender}</div>;
          })}
        </Slider>
      </Suspense>
    </>
  );
};

export default CompaniesSPC;

const Pharmacies = () => {
  const isMdUp = useResponsive("up", "md");
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        gap: isMdUp ? "50px" : "20px",
        marginBottom: "10px",
      }}
    >
      <img
        src={paydensPharmacy}
        alt="Paydens Pharmacy"
        title="Paydens Pharmacy"
        style={{
          width: "160px",
          height: "auto",
        }}
        loading="lazy"
      />
      <img
        src={jardinesPharmacy}
        alt="Jardines Pharmacy"
        title="Jardines Pharmacy"
        style={{
          width: "300px",
          height: "auto",
        }}
        loading="lazy"
      />
      <img
        src={universityOfWolverhampton}
        alt="University of Wolverhampton"
        title="University of Wolverhampton"
        style={{
          width: "220px",
          height: "auto",
        }}
        loading="lazy"
      />
    </div>
  );
};
