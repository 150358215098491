import dayjs, { Dayjs } from "dayjs";

type DateType = Dayjs;

class DateService {
  constructor() {}

  static MONTH_YEAR_FORMAT = "MMMM YYYY";

  static parseServerDate(date: string | undefined | null): Dayjs {
    if (!date) return DateService.newDate();
    return dayjs(date).tz("Europe/London");
  }

  static newDate(): Dayjs {
    return dayjs().tz("Europe/London");
  }

  // e.g. 01/01/2021 12:00:00
  static formatOne(date: Dayjs): string {
    return date.format("DD/MM/YYYY HH:mm:ss");
  }

  // e.g. Friday 1 January 2021
  static formatEight(date: Dayjs): string {
    return date.format("dddd D MMMM YYYY");
  }

  // e.g. 12:00 pm
  static formatThree(date: Dayjs): string {
    return date.format("h:mm a");
  }

  // e.g. Fri 1 Jan
  static formatFour(date: Dayjs): string {
    return date.format("ddd D MMM");
  }

  // e.g. 1 January 2021
  static formatFive(date: Dayjs): string {
    return date.format("D MMMM YYYY");
  }

  // e.g. 1 January 2021, 12:00 pm
  static formatSix(date: Dayjs): string {
    return date.format("D MMMM YYYY, h:mm a");
  }

  // e.g. January 2021
  static formatMonthAndYear(date: Dayjs): string {
    return date.format(DateService.MONTH_YEAR_FORMAT);
  }

  static formatForAPI(date: Dayjs): string {
    return date.format("YYYY-MM-DDTHH:mm:ss");
  }
}

export default DateService;

export type { DateType };
