import React from "react";
import CustomAvatar from "./CustomAvatar";
import { Typography, Popover, Box, Divider, Button } from "@mui/material";
import ModeOptions from "../../settings/drawer/components/ModeOptions";
import { ChangePasswordModal } from "./ChangePasswordModal";
import { useNavigate } from "react-router-dom";
import RemainingAccessModal from "src/components_with_stories/remaining_access_modal/RemainingAccessModal";

const UserProfile = ({ name, email, logout, isSuperuser }) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isAccessModalVisible, setIsAccessModalVisible] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeUserProfilePopup = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "user-profile-popover" : undefined;

  return (
    <div>
      <CustomAvatar id={id} onClick={handleClick} name={name} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closeUserProfilePopup}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ mt: 2, mb: 1.5, ml: 2, mr: 2 }}>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {name}
          </Typography>
          <Typography variant="body2">{email}</Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        {isSuperuser && (
          <Box sx={{ mt: 2, mb: 1.5, ml: 2, mr: 2 }}>
            <div style={{ display: "flex" }}>
              <Typography variant="body2">Admin Dashboard: </Typography>
              <Button
                variant="text"
                color="primary"
                fullWidth
                style={{ padding: "1px", width: "initial" }}
                onClick={() => {
                  closeUserProfilePopup();
                  navigate("/admin-dashboard/");
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  Open
                </Typography>
              </Button>
            </div>
            <div style={{ display: "flex" }}>
              <Typography variant="body2">Admin Site: </Typography>
              <Button
                variant="text"
                color="primary"
                href="/admin/"
                fullWidth
                style={{ padding: "1px", width: "initial" }}
              >
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  Open
                </Typography>
              </Button>
            </div>
          </Box>
        )}

        <Divider sx={{ borderStyle: "dashed" }} />

        <Box sx={{ mt: 2, mb: 1.5, ml: 2, mr: 2 }}>
          <div style={{ display: "flex" }}>
            <Typography variant="body2">Remaining Access: </Typography>
            <Button
              variant="text"
              color="primary"
              onClick={() => setIsAccessModalVisible(true)}
              fullWidth
              style={{ padding: "1px", width: "initial" }}
            >
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                View
              </Typography>
            </Button>
          </div>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Box sx={{ m: 1 }}>
          <ModeOptions />
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />
        <Box sx={{ m: 1 }}>
          <Button
            variant="text"
            color="error"
            onClick={() => setIsModalVisible(true)}
            fullWidth
            sx={{ alignItems: "initial", justifyContent: "initial" }}
          >
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Change Password
            </Typography>
          </Button>
          <Button
            variant="text"
            color="error"
            onClick={logout}
            fullWidth
            sx={{ alignItems: "initial", justifyContent: "initial" }}
          >
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Logout
            </Typography>
          </Button>
        </Box>
      </Popover>
      {isModalVisible && (
        <ChangePasswordModal
          handleModalClose={() => setIsModalVisible(false)}
          closeUserProfile={closeUserProfilePopup}
        />
      )}
      {isAccessModalVisible && (
        <RemainingAccessModal
          handleModalClose={() => {
            setIsAccessModalVisible(false);
            closeUserProfilePopup();
          }}
        />
      )}
    </div>
  );
};

export default UserProfile;
