import React, { useState, useEffect } from "react";
import { PrismicRichText, usePrismicClient } from "@prismicio/react";

import { errorToast } from "src/components_with_stories/toast";

interface FAQSection {
  title: string;
  order: number;
  displayOnPage: string;
  content: FAQContent[];
}

interface FAQContent {
  id: string;
  question: string;
  answer: JSX.Element;
}

export const FAQContext = React.createContext(
  {} as {
    faqQuestionGroups: FAQSection[] | undefined;
    getContentForPage: (page: string) => FAQSection[] | null | undefined;
  }
);

export const FAQContextProvider = (props: React.PropsWithChildren<Record<string, never>>) => {
  const [faqQuestionGroups, setFaqQuestionGroups] = useState<FAQSection[] | undefined>(undefined);
  const client = usePrismicClient();

  useEffect(() => {
    client
      .getAllByType("faq_question", {
        fetchLinks: [
          "faq_question_group.question_group_title",
          "faq_question_group.question_group_order",
          "faq_question_group.display_on_page",
        ],
      })
      .then((response) => {
        const faqQuestions = response;
        if (!faqQuestions) {
          errorToast("Failed to load FAQ questions.");
          return;
        }
        const groups = getFAQQuestionGroups(faqQuestions);
        const faqQuestionGroups = formatFAQQuestionGroups(groups);
        setFaqQuestionGroups(faqQuestionGroups);
      })
      .catch((error) => {
        errorToast("Failed to load FAQ questions.");
        console.error("Failed to fetch FAQ questions", error);
      });
  }, []);

  const getContentForPage = (page: string) => {
    const filtered = faqQuestionGroups?.filter((group) => group.displayOnPage === page);
    if (filtered === undefined) return undefined;
    if (filtered.length > 0) {
      return filtered;
    }
    return null;
  };

  return (
    <FAQContext.Provider
      value={{
        faqQuestionGroups: faqQuestionGroups,
        getContentForPage,
      }}
    >
      {props.children}
    </FAQContext.Provider>
  );
};

const getFAQQuestionGroups = (faqQuestions: any) => {
  const groups: {
    title: string;
    order: number;
    displayOnPage: string;
    questions: {
      id: string;
      data: {
        question_title: { text: string }[];
        question_order: number;
        answer: any;
      };
    }[];
  }[] = [];
  faqQuestions.forEach((question: any) => {
    const group = question.data.question_group;
    const title_fields = group.data.question_group_title;
    const title = title_fields[0].text;
    const order = group.data.question_group_order;
    const displayOnPage = group.data.display_on_page;

    const x = groups.filter((item) => item.title === title);
    if (x.length === 0) {
      groups.push({ title, order, displayOnPage, questions: [question] });
    } else {
      x[0].questions.push(question);
    }
  });

  return groups;
};

const formatFAQQuestionGroups = (
  faqQuestionGroups: {
    title: string;
    order: number;
    displayOnPage: string;
    questions: { id: string; data: { question_title: { text: string }[]; question_order: number; answer: any } }[];
  }[]
) => {
  return faqQuestionGroups
    .map(({ title, order, displayOnPage, questions }) => {
      return {
        title,
        order,
        displayOnPage,
        content: questions
          .map((question) => {
            return {
              id: question.id,
              order: question.data.question_order ?? 0,
              question: question.data.question_title[0].text,
              answer: <PrismicRichText field={question.data.answer} />,
            };
          })
          .sort((a, b) => a.order - b.order), // sort questions by order
      };
    })
    .sort((a, b) => a.order - b.order); // sort groups by order
};
