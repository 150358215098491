import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Divider } from "@mui/material";

import PageTemplate from "src/pages/PageTemplate";

import CompaniesSPC from "src/newComponents/CompaniesSPC";
import MeetTheTeamSPC from "src/newComponents/MeetTheTeamSPC";
import MiniFAQsSPC from "src/newComponents/MiniFAQsSPC";

import WhoWeAreSPC from "./components/WhoWeAreSPC";
import OurStorySPC from "./components/OurStorySPC";
import { FAQContext } from "src/context/FAQContext";

const AboutUsPage = () => {
  const { getContentForPage } = useContext(FAQContext);
  const miniFaqContent = getContentForPage("About Us");

  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/about/" }]}>
        <title>About Us | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="Meet Marvin & Umar, the creators of our highly rated platform. Discover why we're your trusted partner for foundation year training and success."
        />
        <meta
          name="keywords"
          content="Meet the Team, Co-founders Marvin & Umar, Who we are, How Pre-Reg Shortcuts supports you, E-Learning Courses, Foundation Year Training, Our milestones"
        />
      </Helmet>
      <PageTemplate>
        <WhoWeAreSPC />
        <Divider sx={{ mt: { xs: 9, md: 8 } }} style={{ borderColor: "transparent" }} />
        <OurStorySPC />
        <Divider sx={{ mt: { xs: 9, md: 8 } }} style={{ borderColor: "transparent" }} />
        <MeetTheTeamSPC />
        <Divider sx={{ mt: { xs: 9, md: 8 } }} style={{ borderColor: "transparent" }} />
        <CompaniesSPC />
        <Divider sx={{ mt: { xs: 9, md: 8 } }} style={{ borderColor: "transparent" }} />
        <MiniFAQsSPC topics={miniFaqContent} />
      </PageTemplate>
    </>
  );
};

export default AboutUsPage;
