import { Divider, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import LoadingScreen from "src/components/loading-screen";
import { errorToast } from "src/components_with_stories/toast";
import { AccessContext } from "src/context/AccessContext";
import { AppContext } from "src/context/AppContext";
import useIsLightMode from "src/hooks/useIsLightMode";
import ProgrammeResourceModel from "src/models/ProgrammeResourceModel";
import ProgrammeResourceType from "src/models/ProgrammeResourceType";
import ResourceModelType from "src/models/ResourceModelType";
import NoAccessToFTP from "src/newComponents/NoAccessToFTP";
import PageTemplate from "src/pages/PageTemplate";
import ProgrammeResourceRepository from "src/repository/programmeResourcesRepository";
import TrainingPlan from "./components/TrainingPlan";
import WorkbooksSection from "./components/WorkbooksSection";
import MiniFAQsSPC from "src/newComponents/MiniFAQsSPC";
import { FAQContext } from "src/context/FAQContext";

const ProgrammeResourcesPage = () => {
  const { getContentForPage } = useContext(FAQContext);
  const miniFaqContent = getContentForPage("Programme Resources");

  const { isAuthenticated } = useContext(AppContext);
  const { getResourceModelByResourceType } = useContext(AccessContext);
  const [programmeResources, setProgrammeResources] = useState<ProgrammeResourceModel[]>();
  const [isLoading, setIsLoading] = useState(true);
  const isLightMode = useIsLightMode();

  const resourceModel = getResourceModelByResourceType(ResourceModelType.foundationTrainingProgramme);
  const userHasAccess = resourceModel?.access?.userHasValidAccess ?? false;

  useEffect(() => {
    const repository = new ProgrammeResourceRepository();
    repository
      .getProgrammeResources()
      .then((programmeResources: ProgrammeResourceModel[]) => setProgrammeResources(programmeResources))
      .catch(() => errorToast("Failed to fetch programme resources.", isLightMode))
      .finally(() => setIsLoading(false));
  }, [isAuthenticated]);

  // resourcemodel for foundation training program is required to show the programme resources
  const showLoadingSpinner = isLoading || !resourceModel;

  const trainingPlan = programmeResources?.find((programme) => programme.type === ProgrammeResourceType.trainingPlan);
  const revisionChecklists = programmeResources?.filter(
    (programme) => programme.type === ProgrammeResourceType.revisionChecklist
  );

  return (
    <>
      {showLoadingSpinner && <LoadingScreen />}
      <Helmet link={[{ rel: "canonical", href: "/programme-resources/" }]}>
        <title>Programme Resources | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="Programme resources include our GPhC approved training plan, workbooks for BNF, calculations, MEP, and OTC, revision checklists, and over 50 study guides. These downloadable resources support trainee pharmacists in their GPhC exam revision."
        />
        <meta
          name="keywords"
          content="programme resources, GPhC approved training plan, BNF workbooks, pharmacy calculations workbooks, MEP workbooks, OTC workbooks, revision checklists, GPhC exam revision, study guides for trainee pharmacists, foundation pharmacist training programme, GPhC exam, trainee pharmacist support"
        />
      </Helmet>
      <PageTemplate title="Programme Resources">
        <Typography sx={{ margin: "1rem 0" }}>
          Access programme resources along with the exam revision courses in your Foundation Training Year as extra
          support. You'll find our programme resources include study guides such as the GPhC approved training plan,
          workbooks and revision checklist.
        </Typography>

        {!userHasAccess && <NoAccessToFTP accessToText="Programme Resources" />}

        <Grid container spacing={{ xs: 0, md: 3 }}>
          <Grid item xs={12} md={5}>
            {trainingPlan && <TrainingPlan trainingPlan={trainingPlan} />}
          </Grid>
          <Grid item xs={12} md={7}>
            {revisionChecklists && (
              <WorkbooksSection
                title="Revision Checklist"
                description={
                  <div>
                    <p>
                      Are you looking to start your revision for the GPhC assessment? Use our revision checklist, the
                      ultimate tool for mastering all topics in the GPhC registration assessment. This includes:
                    </p>

                    <ul>
                      <li>
                        Progress Track: Keep track of your revision progress and stay motivated as you tick off
                        completed sections.
                      </li>
                      <li>
                        Targeted Revision: Identify strengths and weaknesses and spend more time with focused study.
                      </li>
                      <li>
                        Efficient Study Guide: A clear, organised checklist builds your confidence, saves time and
                        reduces stress.
                      </li>
                    </ul>
                    <p>Note: Only accessible if you are enrolled on the Foundation Pharmacist Training Programme</p>
                  </div>
                }
                programmeResources={revisionChecklists}
              />
            )}
            <WorkbooksSection
              title="Workbooks"
              description={
                <div>
                  <p>
                    Cover all topics to prepare for the GPhC registration assessment for structured learning. Our
                    workbooks cover all topics in the GPhC framework in our exam revision courses (Calculations, BNF,
                    MEP, OTC courses). This includes:
                  </p>
                  <ul>
                    <li>
                      Detailed Topics Breakdown: All topics in the exam revision courses are clearly defined with
                      diagrams for easy understanding.
                    </li>
                    <li>
                      GPhC Style Questions: Practice GPhC style questions to familiarise yourself with the exam format
                      and question style.
                    </li>
                    <li>
                      Case Studies with Active Recall Questions: Real-world case studies and active recall questions to
                      deepen understanding and improve retention.
                    </li>
                    <li>
                      Direct Links to Exam Revision Courses: Allows you to integrate visual and textbased learning.
                    </li>
                  </ul>
                  <p>Note: Workbooks can be found in each chapter of the Exam Revision Courses.</p>
                </div>
              }
            />
          </Grid>
        </Grid>

        <Divider sx={{ mt: { xs: 9, md: 8 } }} style={{ borderColor: "transparent" }} />
        <MiniFAQsSPC topics={miniFaqContent} />
      </PageTemplate>
    </>
  );
};

export default ProgrammeResourcesPage;
