import { useFormContext, Controller } from "react-hook-form";

import TextField, { TextFieldProps } from "@mui/material/TextField";

type RHFTextFieldProps = TextFieldProps & {
  name: string;
  isDisabled?: boolean;
  hasFocus?: boolean;
};

export default function RHFTextField({
  name,
  helperText,
  isDisabled,
  tabIndex,
  hasFocus,
  ...other
}: RHFTextFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          disabled={isDisabled}
          value={typeof field.value === "number" && field.value === 0 ? "" : field.value}
          inputRef={(input) => input && hasFocus && input.focus()}
          error={!!error}
          helperText={error ? error?.message : helperText}
          inputProps={tabIndex ? { tabIndex: tabIndex } : undefined}
          {...other}
        />
      )}
    />
  );
}
