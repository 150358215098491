import { Box, Stack, Divider, Container, Typography, Unstable_Grid2 as Grid, Avatar, Alert } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs";
import Image from "src/components/image/Image";
import Iconify from "src/components/icon/Iconify";
import RenderTextAreaContent from "src/custom_components/RenderTextAreaContent";
import imagePlaceholder from "src/assets/placeholder.jpeg";

import { AccessContext } from "src/context/AccessContext";
import { useContext } from "react";
import CourseModel from "src/models/CourseModel";
import DateService from "src/utils/dateService";

export default function ElearningCourseDetailsHero({ course }: { course: CourseModel }) {
  const { getResourceModelByResourceId } = useContext(AccessContext);
  const resourceModel = course.resourceId ? getResourceModelByResourceId(course.resourceId) : null;

  const totalHours = course.hours;
  const accessEnds = resourceModel?.access?.accessEnds;

  const hasAccessEnded = accessEnds ? accessEnds.isBefore(DateService.newDate()) : false;
  const doesAccessExpireSoon = accessEnds
    ? accessEnds.isBefore(DateService.newDate().add(4, "week")) && !hasAccessEnded
    : false;

  return (
    <Box
      sx={{
        bgcolor: "background.neutral",
        mt: 10,
        pb: { xs: 5, md: 10 },
      }}
    >
      <Container sx={{ overflow: "hidden" }}>
        {doesAccessExpireSoon && accessEnds && (
          <Alert sx={{ mt: 3 }} icon={<AccessTimeIcon fontSize="inherit" />} severity="warning">
            Your access to this course expires on {DateService.formatEight(accessEnds)}.
          </Alert>
        )}
        {hasAccessEnded && accessEnds && (
          <Alert sx={{ mt: 3 }} icon={<AccessTimeIcon fontSize="inherit" />} severity="error">
            Your access to this course ended on {DateService.formatEight(accessEnds)}.
          </Alert>
        )}

        <CustomBreadcrumbs
          links={[{ name: "Home", href: "/" }, { name: "Courses", href: "/courses/" }, { name: course.title }]}
          sx={{
            pt: doesAccessExpireSoon ? 2 : 5,
            mb: { xs: 5, sm: 10 },
          }}
        />

        <Grid container spacing={{ xs: 5, sm: 10 }} direction="row" style={{ alignItems: "center" }}>
          <Image
            alt={course.title}
            src={resourceModel?.image ?? imagePlaceholder}
            sx={{
              objectFit: "cover",
              width: { sm: 180, md: 240 },
              height: { sm: 180, md: 240 },
              p: { xs: 3, sm: 0 },
              ml: { sm: 5 },
              mt: { sm: -2 },
            }}
          />

          <Grid xs={12} sm={9}>
            <Stack spacing={2}>
              <Stack spacing={2} alignItems="flex-start">
                <Typography variant="h3" component="h1">
                  {course.title}
                </Typography>

                <RenderTextAreaContent content={course.description} />
              </Stack>

              <Divider sx={{ borderStyle: "dashed" }} />

              <Stack spacing={2}>
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  sx={{
                    "& > *": { my: 0.5, mr: 3 },
                  }}
                >
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    alignItems="center"
                    sx={{
                      color: "text.disabled",
                    }}
                  >
                    <Stack direction="row" alignItems="center" sx={{ mr: 2.5 }}>
                      <Avatar src={course.tutor.profile_image ?? undefined} />

                      <Typography variant="body2" sx={{ ml: 1, mr: 0.5 }}>
                        {course.tutor.name}
                      </Typography>
                    </Stack>

                    <Stack direction="row" alignItems="center" sx={{ typography: "body2", mr: 2.5 }}>
                      <Iconify icon="carbon:time" sx={{ mr: 1 }} /> {`${totalHours} hours`}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
